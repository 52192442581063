import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';

const Header = ({ siteTitle }) => (
    <header
        style={{
            position: `absolute`,
            background: `#ffd990`,
            width: `100vw`,
            padding: `8px 8px 0 8px`,
        }}
    >
        <div>
            <h1 style={{ margin: 0, fontSize: `calc(10px + 2vmin)` }}>
                <Link
                    to="/"
                    style={{
                        color: `white`,
                        textDecoration: `none`,
                    }}
                >
                    {siteTitle}
                </Link>
            </h1>
        </div>
    </header>
);

Header.propTypes = {
    siteTitle: PropTypes.string.isRequired,
};

export default Header;
