/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';

import Header from './header';
import Image from './image';
import './layout.css';

const Layout = ({ children }) => {
    const data = useStaticQuery(graphql`
        query SiteTitleQuery {
            site {
                siteMetadata {
                    title
                    githubProfile
                }
            }
        }
    `);

    return (
        <div
            style={{
                display: `flex`,
                flexDirection: `column`,
                alignItems: `center`,
            }}
        >
            <Header siteTitle={data.site.siteMetadata.title} />
            <main
                style={{
                    display: `flex`,
                    alignItems: `center`,
                    height: `100vh`,
                    fontSize: `calc(10px + 12vmin)`,
                    lineHeight: `calc(10px + 12vmin)`,
                }}
            >
                {children}
            </main>
            <footer
                style={{
                    position: `absolute`,
                    bottom: 0,
                    display: `flex`,
                    height: `24px`,
                    width: `100vw`,
                    alignItems: `center`,
                    justifyContent: `flex-end`,
                    padding: `0 8px 8px 8px`,
                }}
            >
                <a
                    href={data.site.siteMetadata.githubProfile}
                    className="github-mark"
                >
                    <Image />
                </a>
            </footer>
        </div>
    );
};

Layout.propTypes = {
    children: PropTypes.node.isRequired,
};

export default Layout;
